export const PublicRoutes = {
    Home: '/',
    Basics: '/basics',
    BasicsCourse: '/basics/:course',
    OtherCourses: '/other-courses',
    OtherCoursesCourse: '/other-courses/:course',
    Admission: '/admission',
    About: '/about',
    Projects: '/projects',
    Bebras: '/bebras',
    Books: '/books',
    Blog: '/blog',
    Contact: '/contact',
    ContactKnowMore: '/contact/:knowMore',
    Register: '/register',
    RegisterCourse: '/register/:course',
    PrivacyPolicy: '/privacy-policy',
    TermsOfUse: '/terms-of-use',
    Facebook: 'https://www.facebook.com/CodzillaKids/',
    Instragram: 'https://www.instagram.com/codzilla_kids/',
    LinkedIn: 'https://www.linkedin.com/company/codzillakids/',
    Youtube: 'https://www.youtube.com/channel/UCiOdVX7qoE07jLVGD6Ad79A',
    Dinologika: 'https://books.am/am/37-1.html?fbclid=IwAR27Zp0L8zd9edXkxCN88HSWYjRICAzom-wt9hM0j2aM2XmXRR4CFDTsUek'
};
