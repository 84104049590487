import { initializeApp } from 'firebase/app';
//import { initializeAnalytics } from 'firebase/analytics';

export function initializeFirebase() {
    const firebaseConfig = {
        apiKey: 'AIzaSyCZGdfonS93wFCNldiKY13Rn3cBqJoOPRg',
        authDomain: 'codzilla-website.firebaseapp.com',
        databaseURL: 'https://codzilla-website.firebaseio.com',
        projectId: 'codzilla-website',
        storageBucket: 'codzilla-website.appspot.com',
        messagingSenderId: '76781475337',
        appId: '1:76781475337:web:ee1ea14ee528b08ae04ad8',
        measurementId: 'G-RCM9F3L6M1',
    };

    const app = initializeApp(firebaseConfig);
    //initializeAnalytics(app);
}
