/* Code split https://medium.com/@ohsiwon/code-splitting-with-dynamic-import-test-and-learn-28bc2a06d1b8 */

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PublicRoutes } from "./components/common/PublicRoutes";
import { ProtectedRoutes } from "./components/common/ProtectedRoutes";
import ScrollToTop from "./components/helpers/ScrollToTop";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
const Home = lazy(() => import(/* webpackPrefetch: true */"./components/pages/home/Home"));
const Admission = lazy(() => import(/* webpackPrefetch: true */"./components/pages/admission/Admission"));
const About = lazy(() => import(/* webpackPrefetch: true */"./components/pages/about/About"));
const Books = lazy(() => import(/* webpackPrefetch: true */"./components/pages/books/Books"));
const Projects = lazy(() => import(/* webpackPrefetch: true */"./components/pages/projects/Projects"));
const Bebras = lazy(() => import(/* webpackPrefetch: true */"./components/pages/bebras/Bebras"));
const Contacts = lazy(() => import(/* webpackPrefetch: true */"./components/pages/contact-us/Contacts"));
const Register = lazy(() => import(/* webpackPrefetch: true */"./components/pages/register/Register"));
const Basics = lazy(() => import(/* webpackPrefetch: true */"./components/pages/basics/Basics"));
const OtherCourses = lazy(() => import(/* webpackPrefetch: true */"./components/pages/other-courses/OtherCourses"));
const PrivacyPolicy = lazy(() => import(/* webpackPrefetch: true */"./components/pages/privacy-policy/PrivacyPolicy"));
const TermsOfUse = lazy(() => import(/* webpackPrefetch: true */"./components/pages/terms-of-use/TermsOfUse"));
const Blog = lazy(() => import(/* webpackPrefetch: true */"./components/pages/blog/Blog"));
const CompetitionLogicBook1 = lazy(() => import(/* webpackPrefetch: true */"./components/protected-components/logic-book-1/CompetitionLogicBook1"));

function App() {
  return (
    <div className="font-mardoto flex-wrapper overflow-hidden select-none">
      <Suspense fallback={<div></div>}>
        <Router>
          <ScrollToTop />
          <Navigation />
          <div className="mt-16">
            <Routes>
              <Route exact path={PublicRoutes.Home} element={<Home />} />
              <Route path={PublicRoutes.About} element={<About />} />
              <Route path={PublicRoutes.Admission} element={<Admission />} />
              <Route path={PublicRoutes.BasicsCourse} element={<Basics />} />
              <Route path={PublicRoutes.OtherCoursesCourse} element={<OtherCourses />} />
              <Route path={PublicRoutes.Projects} element={<Projects />} />
              <Route path={PublicRoutes.Bebras} element={<Bebras />} />
              <Route path={PublicRoutes.Books} element={<Books />} />
              <Route path={PublicRoutes.Blog} element={<Blog />} />
              <Route path={PublicRoutes.Contact} element={<Contacts />} />
              <Route path={PublicRoutes.Register} element={<Register />} />
              <Route path={PublicRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
              <Route path={PublicRoutes.TermsOfUse} element={<TermsOfUse />} />
              {/* Protected Routes */}
              <Route path={ProtectedRoutes.CompetitionLogicBook1} element={<CompetitionLogicBook1 />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
