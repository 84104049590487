export const CoursesHashes = {
    Basics: 'basics',
    Junior: 'junior',
    Middle: 'middle',
    Senior: 'senior',
    Youthful: 'youthful',
    Young: 'young',

    Logic: 'logic',
    Scratch: 'scratch',
    Prlogic: 'prlogic',
    Other: 'other',
    Robotics: 'robotics',
    Chess: 'chess',
    English: 'english',
    Classes: 'classes',
    School: 'school',
};