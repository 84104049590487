import { NavLink } from 'react-router-dom';

export const NavigationLink = ({ path, children, onClick, className = '' }) => {
    return (
        <NavLink
            to={path}
            className={({ isActive }) =>
                `${(isActive ? "text-orange-700" : "text-gray")} cursor-pointer my-1 lg:inline-block block hover:text-orange-700 mr-8 ${className}`
            }
            onClick={onClick}
        >
            {children}
        </NavLink>
    );
};
