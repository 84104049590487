import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { PublicRoutes } from './../common/PublicRoutes';
import FooterPolygons from './assets/footer-polygon.svg';

const initialValues = {
    email: '',
};

const Footer = () => {
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('contacts.invalidEmail')).required(t('contacts.emailRequired')),
    });

    return (
        <div
            className="bg-gray-800 bg-no-repeat bg-right-out"
            style={{
                backgroundImage: `url(${FooterPolygons})`,
            }}
        >
            <div className="container m-auto lg:flex pt-20 px-4 lg:px-0">
                <div className="lg:w-1/2">
                    <div className="text-orange text-xs font-mardoto-bold mb-4">{t('footer.contactUs')}</div>
                    <div className="flex flex-col lg:flex-row">
                        <div className="lg:w-1/2 text-white text-sm leading-loose mb-10 lg:mb-0">
                            +37493 509 659 <br />
                            +37495 509 609 <br />
                            Info@codzilla.am <br />
                            {t('contacts.address')}
                        </div>
                        <div className="lg:w-1/2 text-white text-sm leading-loose mb-10 lg:mb-0">
                            <Link to={PublicRoutes.About} className="hover:text-orange">
                                {t('routes.about')}
                            </Link>
                            <br />
                            <Link to={PublicRoutes.PrivacyPolicy} className="hover:text-orange">
                                {t('routes.privacyPolicy')}
                            </Link>
                            <br />
                            <Link to={PublicRoutes.TermsOfUse} className="hover:text-orange">
                                {t('routes.termsOfUse')}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2">
                    <div className="text-white text-sm font-mardoto-bold mb-4">
                        {t('footer.subscribeTitle')}
                    </div>
                    <div className="text-orange text-xs">
                        {t('footer.subscribeContent')} <br />{t('footer.subscribeContent2')}
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            fetch('https://api.sendinblue.com/v3/contacts', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json',
                                    'api-key':
                                        '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',
                                },
                                body: JSON.stringify({
                                    ...values,
                                }),
                            })
                                .then(response => response.json())
                                .then(response => {
                                    resetForm(initialValues);
                                    if (response.id) {
                                        setErrorMessage(false);
                                        setSuccessMessage(true);
                                    } else {
                                        setSuccessMessage(false);
                                        setErrorMessage(true);
                                    }
                                })
                                .catch(error => {
                                    setSuccessMessage(false);
                                    setErrorMessage(true);
                                });
                        }}
                    >
                        {({ errors, touched, isValidating, isSubmitting }) => {
                            return (
                                <Form>
                                    <Field
                                        name="email"
                                        className="p-2 rounded text-xs text-white bg-gray-800 border-orange border-solid border w-1/2 lg:w-56 placeholder-white"
                                        type="email"
                                        placeholder={t('contacts.email')}
                                    />
                                    <button
                                        className="p-2 ml-4 text-xs rounded bg-white hover:bg-orange-400 active:bg-orange-700 text-orange"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {t('footer.subscribe')}
                                    </button>
                                    {errors.email && touched.email && (
                                        <p className="text-red-700 text-xs">{errors.email}</p>
                                    )}
                                    {errorMessage && (
                                        <p className="text-red-700 text-xs">
                                            {t('footer.error')}
                                        </p>
                                    )}
                                    {successMessage && (
                                        <p className="text-green-700 text-xs">
                                            {t('footer.success')}
                                        </p>
                                    )}
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className="container m-auto text-orange mt-4 text-center lg:text-left">
                <a href={PublicRoutes.Facebook} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                        icon={faFacebookSquare}
                        size="2x"
                        className="mr-4 hover:text-white"
                    />
                </a>
                <a href={PublicRoutes.LinkedIn} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                        icon={faLinkedin}
                        size="2x"
                        className="mr-4 hover:text-white"
                    />
                </a>
                <a href={PublicRoutes.Instragram} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                        icon={faInstagram}
                        size="2x"
                        className="mr-4 hover:text-white"
                    />
                </a>
                <a href={PublicRoutes.Youtube} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size="2x" className="hover:text-white" />
                </a>
            </div>
            <div className="text-center text-white pb-4">
                © Codzilla | 2019 - {new Date().getFullYear()}
            </div>
        </div>
    );
};

export default Footer;
