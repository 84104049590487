import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { PublicRoutes } from "./../common/PublicRoutes";
import { CoursesHashes } from "./../common/Courses";
import { NavigationLink } from "./internal-components/NavigationLink";
import Logo from "./assets/logo-text.svg";
import LanguagePicker from "../common/LanguagePicker";


const Navigation = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  return (
    <nav className="fixed top-0 w-full z-30 bg-white shadow flex items-center justify-between p-5 border-b border-gray-100">
      <div className="flex items-center mr-6">
        <Link to="/">
          <img
            src={Logo}
            alt="Codzilla"
            width="150px"
            height="34px"
          />
        </Link>
      </div>
      <div className="text-base font-mardoto-bold uppercase tracking-wide hidden lg:block">
        <NavigationLink path={PublicRoutes.About}>
          {t('routes.about')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Admission}>
          {t('routes.admission')}
        </NavigationLink>
        <DropdownMenu path={PublicRoutes.Basics} name={t('courses.title')}>
          <div className="bg-orange-300 p-2 my-2 text-center rounded">
            <span>{t('courses.basics.title')}</span>
          </div>
          <NavigationLink
            path={`${PublicRoutes.Basics}/${CoursesHashes.Junior}`}
          >
            {t('courses.junior.age')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.Basics}/${CoursesHashes.Middle}`}
          >
            {t('courses.middle.age')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.Basics}/${CoursesHashes.Senior}`}
          >
            {t('courses.senior.age')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.Basics}/${CoursesHashes.Youthful}`}
          >
            {t('courses.youthful.age')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.Basics}/${CoursesHashes.Young}`}
          >
            {t('courses.young.age')}
          </NavigationLink>
          <div className="bg-orange-300 my-2 p-2 text-center rounded">
            <span>{t('courses.other.title')}</span>
          </div>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Logic}`}
          >
            {t('courses.logic.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Scratch}`}
          >
            {t('courses.scratch.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Prlogic}`}
          >
            {t('courses.prlogic.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Robotics}`}
          >
            {t('courses.robotics.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Chess}`}
          >
            {t('courses.chess.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.English}`}
          >
            {t('courses.english.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Classes}`}
          >
            {t('courses.classes.title')}
          </NavigationLink>
          <NavigationLink
            path={`${PublicRoutes.OtherCourses}/${CoursesHashes.School}`}
          >
            {t('courses.school.title')}
          </NavigationLink>
        </DropdownMenu>
        <NavigationLink path={PublicRoutes.Projects}>
          {t('routes.projects')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Bebras}>
          {t('routes.bebras')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Books}>
          {t('routes.books')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Blog}>
          {t('routes.blog')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Contact}>
          {t('routes.contact')}
        </NavigationLink>
        <NavigationLink
          path={PublicRoutes.Register}
          className="border-solid border-2 border-orange-400 rounded p-2"
        >
          {t('routes.register')}
        </NavigationLink>
      </div>
      <LanguagePicker />
      <MobileNavigation
        className="lg:hidden"
        isOpen={isOpen}
        toggleOpen={toggle}
      >
        <NavigationLink path={PublicRoutes.About} onClick={toggle}>
          {t('routes.about')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Admission} onClick={toggle}>
          {t('routes.admission')}
        </NavigationLink>
        <NavigationLink path={`${PublicRoutes.Basics}/${CoursesHashes.Junior}`} onClick={toggle}>
          {t('courses.basics.title')}
        </NavigationLink>
        <NavigationLink path={`${PublicRoutes.OtherCourses}/${CoursesHashes.Logic}`} onClick={toggle}>
          {t('courses.other.title')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Projects} onClick={toggle}>
          {t('routes.projects')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Bebras} onClick={toggle}>
          {t('routes.bebras')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Books} onClick={toggle}>
          {t('routes.books')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Blog} onClick={toggle}>
          {t('routes.blog')}
        </NavigationLink>
        <NavigationLink path={PublicRoutes.Contact} onClick={toggle}>
          {t('routes.contact')}
        </NavigationLink>
        <NavigationLink
          path={PublicRoutes.Register}
          onClick={toggle}
          className="border-solid border-2 border-orange-400 rounded p-2"
        >
          {t('routes.register')}
        </NavigationLink>
      </MobileNavigation>
    </nav>
  );
};

export default Navigation;

const DropdownMenu = ({ name, children }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className="relative inline-block hover:text-orange-700 mr-8"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div>
        {name}
      </div>
      {open && (
        <div className="absolute z-10 bg-white p-4 shadow-md w-auto min-w-max">
          {children.map((c, i) => (
            <div className="normal-case text-gray" key={i}>
              {c}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileNavigation = ({ className = "", isOpen, toggleOpen, children }) => {
  return (
    <div className={`relative ${className}`}>
      <div onClick={toggleOpen} className="text-orange cursor-pointer">
        <FontAwesomeIcon size="2x" icon={isOpen ? faTimes : faBars} />
      </div>
      {isOpen && (
        <div className="absolute right-0 shadow-lg bg-white p-4 w-56 z-10">
          {children}
        </div>
      )}
    </div>
  );
};
