import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { initializeFirebase } from './firebase';
import './i18n'; // Import i18n config

// Initialize Firebase
initializeFirebase();

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.
root.render(<App />); // Initial render

