// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const savedLanguage = localStorage.getItem('selectedLanguage');

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        returnObjects: true,
        lng: savedLanguage,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            // path where resources get loaded from
            loadPath: '/locales/{{lng}}/translation.json',
        },
    }, (err, t) => {
        if (err) return console.log('Something went wrong loading', err);
        console.log('i18next is ready...');
    });;

export default i18n;
